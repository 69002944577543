<template>
  <div class="page" v-if="mainPage">
    <!--标题-->
    <ls-title title="体检数据"></ls-title>

      <!-- 条件框 -->
      <div class="conddiv">
        <el-input v-model="condition" placeholder="搜索用户姓名" prefix-icon="el-icon-search" style="width:200px;"></el-input>

        <el-date-picker v-model="dateTime" style="margin-left:5px;" type="daterange" range-separator="至" format="YYYY 年 MM 月 DD 日"
                value-format="YYYY-MM-DD" start-placeholder="开始日期" end-placeholder="结束日期" clearable>
        </el-date-picker>

        <el-select v-model="dataType" style="margin-left:5px;" placeholder="体检项目" size="large" clearable>
          <el-option v-for="item in dataTypeOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>

        <el-select v-model="state" style="margin-left:5px;" placeholder="结果分析" size="large" clearable>
          <el-option v-for="item in stateOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button type="warning" style="margin-left:5px;" @click="search">搜索</el-button>
      </div>

      <!-- 表格 -->
      <el-table :data="dataList" border style="width: 100%;border-radius: 10px;" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
        <el-table-column prop="createTime" label="体检时间" width="180" label-class-name="tablehead" align="center " >
          <template #default="scope">
            <span>{{scope.row.createTime.substring(0,16)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="role == 1" prop="unitName" label="所属机构"  label-class-name="tablehead" align="center " />
        <el-table-column prop="userName" label="姓名" width="120" label-class-name="tablehead" align="center " />
        <el-table-column prop="sex" label="性别"  width="80" label-class-name="tablehead" align="center " >
          <template #default="scope">
            <span v-if="scope.row.sex == 1">男</span>
            <span v-if="scope.row.sex == 0">女</span>
          </template>
        </el-table-column>
        <el-table-column prop="dataTypeDesc" width="100" label="体检项目" label-class-name="tablehead" align="center " />
        <el-table-column prop="result" label="体检结果" label-class-name="tablehead" align="center " >
          <template #default="scope">
            <span v-if="scope.row.dataType != 108" >{{scope.row.result}}</span>
            <span v-if="scope.row.dataType == 108" class="textbtn" @click="seeImage(scope.row)">{{scope.row.result}}(点击查看)</span>
          </template>
        </el-table-column>
        <el-table-column prop="source" width="160" label="数据来源" label-class-name="tablehead" align="center" />
        <el-table-column prop="stateDescribe" width="100" label="结果分析" label-class-name="tablehead" align="center" >
          <template #default="scope">
            <span v-if="scope.row.state == 0" style="color:green">{{scope.row.stateDescribe}}</span>
            <span v-if="scope.row.state == 1" style="color:red">{{scope.row.stateDescribe}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" label-class-name="tablehead" align="center " width="100">
          <template #default="scope">
            <span class="textbtn" @click="details(scope.row)">详情</span>
          </template>
        </el-table-column> -->
      </el-table>

      <!-- 分页 -->
      <div class="pagination">
        <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
      </div>

      <el-dialog v-model="imageShow" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="true" width="60%">
        <template #title>
          <span style="color: white; font-size: 20px">查看心电图</span>
        </template>
        <el-image :src="imageUrl" :preview-src-list="imageList"></el-image>
      </el-dialog>

  </div>

  <!-- <template v-if="detailsPage">
    <PhysicalDetails @cancel="cancelDetails"></PhysicalDetails>
  </template> -->
</template>

<script>
import {getHealthAllInfos,getEcgInfo} from '../../../api/api'
import until from '../../../until/until'
import session from "../../../store/store";
// import PhysicalDetails from './PhysicalDetails.vue'
export default {
  // components: {
  //   PhysicalDetails
  // },
  data() {
    return {
      emptyText:'加载中',
      mainPage: true,
      detailsPage: false,
      imageShow:false,
      imageList:[],
      imageUrl:'',
      role: 0,
      condition: "",//姓名
      dateTime: '',//条件
      startTime:'',
      endTime:'',
      dataType:"",//体检项目
      dataTypeOption:[
        {value: 101,label: '血压'},
        {value: 102,label: '血糖'},
        {value: 104,label: '体脂'},
        {value: 105,label: '尿酸'},
        {value: 106,label: '血脂'},
        {value: 107,label: '体温'},
        {value: 108,label: '心电'},
        {value: 110,label: '计步'},
        {value: 111,label: '血氧'},
        {value: 113,label: '尿常规'},
        {value: 114,label: '糖化'},
      ],

      state:'',//设备状态
      stateOption:[
        {value: 0,label: '全部'},
        {value: 1,label: '正常'},
        {value: 2,label: '异常'}
      ],
      currentPage: 1,
      size: 10,
      total: 0,//总数
      dataList: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.role = session.getLoginInfo().accountType;
      this.getHealthAllInfos();
    },
    search() {
      if (!until.isNULL(this.dateTime)) {
        this.startTime = this.dateTime[0],
        this.endTime = this.dateTime[1]
      }else {
        this.startTime = '',
        this.endTime = ''
      }
      this.getHealthAllInfos();
    },
    getHealthAllInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
        page:this.currentPage - 1,
        size:this.size,
        startTime:this.startTime,
        endTime:this.endTime,
        condition:this.condition
      }
      if (!until.isNULL(this.dataType)) {
        param.dataType = this.dataType
      }
      if (!until.isNULL(this.state)) {
        param.stateSwitch = this.state
      }else {
        param.stateSwitch = 0
      }
      getHealthAllInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.dataList = res.data.dataInfo;
        }else {
          this.total = 0;
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },

      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getHealthAllInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getHealthAllInfos();
    },
    seeImage(row) {
      let param = {
        uuid:row.uuid,
      }
      getEcgInfo(param).then(res => {
        if (res.data.code == 200) {
          this.imageUrl = res.data.dataInfo.ecgPng
          this.imageShow = true;
          this.imageList.splice(0, this.imageList.length);
          this.imageList.push(res.data.dataInfo.ecgPng);
        }else {
          this.$message.error({ message: res.data.message});
        }
      })

      
    },
    // details(row) {
    //   console.info(row)
    //   this.mainPage = false;
    //   this.detailsPage= true;
    // },
    // cancelDetails() {
    //   this.mainPage = true;
    //   this.detailsPage= false;
    // },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.conddiv {
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 2px 3px 4px var(--el-color-info-light);
  margin-bottom: 20px;
}
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
.textbtn {
  color: #285df0;
  cursor: pointer;
}
</style>
<style>
/* .el-input__prefix {
  top: 5px !important;
} */
.tablehead {
  color: white;
}
</style>